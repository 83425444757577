<template>
  <input
    class="v-styled-slider slider-progress"
    :class="{
      disabled: disabled
    }"
    :aria-label="label"
    :inert="disabled"
    :max="max"
    :min="min"
    :style="SkeletonItems"
    type="range"
    v-model="value"
  />
</template>

<script setup lang="ts">
import type { CanBeDisabled } from '@arora/common'

import type { StyleValue } from 'vue'

const {
  max = 0,
  min = 0,
  originalValue = 0,
  updateVal
} = defineProps<
  CanBeDisabled & {
    label: string
    max?: number
    min?: number
    originalValue?: number
    updateVal: (value: number) => void
  }
>()

const value = computed<number>({
  get: () => originalValue,
  set: (value) => {
    updateVal(value)
  }
})

const SkeletonItems = computed<StyleValue>(() => {
  return {
    '--max': max,
    '--min': min,
    '--value': value.value
  }
})
</script>

<style lang="scss">
@use 'assets/variables';

@mixin track-style {
  background:
    linear-gradient(variables.$PrimaryBackgroundColor, variables.$PrimaryBackgroundColor)
      0 / var(--sx) 100% no-repeat,
    variables.$FormBackgroundNoOpaque;
}

@mixin track-style-active {
  background:
    linear-gradient(variables.$PrimaryBackgroundColor, variables.$PrimaryBackgroundColor)
      0 / var(--sx) 100% no-repeat,
    variables.$FormBackgroundNoOpaque;
}

@mixin thumb-style {
  appearance: none;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: variables.$PrimaryBackgroundColor;
  border: none;
  box-shadow: variables.$InputShadow;
  margin-top: -7.5px;
}

@mixin thumb-style-active {
  /*background: darken($PrimaryBackgroundColor, 10%);*/
  background: variables.$PrimaryBackgroundColor;
}

@mixin track-range-style {
  background: variables.$FormBackgroundNoOpaque;
  border-color: variables.$BorderColor;
}

@mixin track-runnable-style {
  height: 13px;
  border: variables.$BorderWidth solid variables.$BorderColor;
  border-radius: variables.$BorderRadius;
  background: variables.$FormBackgroundNoOpaque;
  box-shadow: none;
}

input[type='range'].v-styled-slider {
  height: 36px;
  width: 100%;
  appearance: none;
  background: transparent;
}

/*progress support*/
input[type='range'].v-styled-slider.slider-progress {
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--value) - var(--min)) / var(--range));
  --sx: calc(13px + var(--ratio) * (100% - 26px));
}

input[type='range'].v-styled-slider:focus {
  outline: none;
}

/*webkit*/
input[type='range'].v-styled-slider::-webkit-slider-thumb {
  @include thumb-style;
}

input[type='range'].v-styled-slider::-webkit-slider-runnable-track {
  @include track-runnable-style;
}

input[type='range'].v-styled-slider::-webkit-slider-thumb:active {
  @include thumb-style-active;
}

input[type='range'].v-styled-slider:active::-webkit-slider-runnable-track {
  @include track-range-style;
}

input[type='range'].v-styled-slider.slider-progress::-webkit-slider-runnable-track {
  @include track-style;
}

input[type='range'].v-styled-slider.slider-progress:active::-webkit-slider-runnable-track {
  @include track-style-active;
}

/*mozilla*/
input[type='range'].v-styled-slider::-moz-range-thumb {
  @include thumb-style;
}

input[type='range'].v-styled-slider::-moz-range-track {
  @include track-runnable-style;
}

input[type='range'].v-styled-slider::-moz-range-thumb:active {
  @include thumb-style-active;
}

input[type='range'].v-styled-slider:active::-moz-range-track {
  @include track-range-style;
}

input[type='range'].v-styled-slider.slider-progress::-moz-range-track {
  @include track-style;
}

input[type='range'].v-styled-slider.slider-progress:active::-moz-range-track {
  @include track-style-active;
}
</style>
